import { EyeOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from
  'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import useNavigateParams from 'common/hooks/useNavigateParams';
import { getAllResultService } from 'common/services/survey';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';
import roles from 'configs/roles';
import { translationSick } from 'extends/Charts/helper';

export type ResultDataTypes = {
  id: number;
  kioskCode: string;
  heartRiskCode: string;
  heartScore: string;
  createdAt: string;
  submittedAt: string;
};

const SurveyResultManagement: React.FC<ActiveRoles> = ({
  roleUpdate,
  roleCreate,
  roleDelete,
  roleOther
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();

  /* Selectors */
  const {
    defaultPageSize,
    advancedFilter,
  } = useAppSelector((state) => state.system);
  const navigateParams = useNavigateParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams.entries());

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* Queries */
  const { data: resultRes, isLoading } = useQuery(
    ['getAllResult', currentPage, currentView, keyword, returnParamsFilter],
    () => getAllResultService({
      page: currentPage,
      limit: currentView,
      kioskCode: keyword,
      ...returnParamsFilter
    }),
  );

  /* Variables */
  const columns: ColumnsType<ResultDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: ResultDataTypes, b: ResultDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ResultDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('surveyResult.source'),
      dataIndex: 'kioskCode',
      key: 'kioskCode',
      render: (_: string, data: any) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.kioskCode}
        </Typography.Text>
      ),
    },
    {
      title: t('surveyResult.heartRisk'),
      dataIndex: 'heartRiskCode',
      key: 'heartRiskCode',
      render: (_: string, data: any) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.heartRiskCode}
        </Typography.Text>
      ),
    },
    {
      title: t('surveyResult.heartScore'),
      dataIndex: 'heartScore',
      key: 'heartScore',
      sorter: {
        compare: (
          a: ResultDataTypes,
          b: ResultDataTypes,
        ) => Number(a.heartScore) - Number(b.heartScore),
      },
      render: (_: string, data: any) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.heartScore}
        </Typography.Text>
      ),
    },
    // Ngày submitted
    {
      title: t('surveyResult.submittedAt'),
      dataIndex: 'submittedAt',
      key: 'submittedAt',
      sorter: {
        compare: (a: ResultDataTypes, b: ResultDataTypes) => {
          const aDate = new Date(a.submittedAt);
          const bDate = new Date(b.submittedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: any) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {formatDateTime(data.submittedAt)}
        </Typography.Text>
      ),
    },
    // --- Ngày tạo
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: ResultDataTypes, b: ResultDataTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: any) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: ResultDataTypes) => (
        <Space>
          <Button
            icon={<EyeOutlined />}
            disabled={!roleOther.includes(roles.SURVEY_RESULT_SHOW)}
            onClick={() => navigate(`${ROUTE_PATHS.SURVEY_RESULT_DETAIL}?id=${_data.id}`)}
          />
        </Space>
      ),
    },
  ];

  const resultData: ResultDataTypes[] = useMemo(() => {
    if (resultRes?.data && resultRes?.data.length > 0) {
      return resultRes?.data.map((item) => ({
        id: item.resultData.id,
        kioskCode: item.resultData.kioskCode || t('kioskManagement.website'),
        heartRiskCode: translationSick(item.resultData.heartRiskCode) || '-',
        heartScore: item.resultData.heartRiskScore || '-',
        createdAt: item.resultData.createdAt || '',
        submittedAt: item.resultData.submittedAt || '',
      }));
    }
    return [];
  }, [resultRes, t]);

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, {
      ...data,
      index: counter
    }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('surveyResult', advancedFilter),
    [advancedFilter]
  );

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  useEffect(() => {
    setSearchParams(mappingFilterToQuery(selectedFilterList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.surveyResult')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={isLoading}
          handleSearch={setKeyword}
          roles={{
            roleCreate,
            roleDelete,
            roleUpdate
          }}
          handleEditPage={(id, _, language) => navigateParams(`${ROUTE_PATHS.SURVEY_RESULT_DETAIL}`, `id=${id}&locale=${language}`)}
          tableProps={{
            initShowColumns: ['id', 'kioskCode', 'heartRiskCode', 'heartScore', 'submittedAt', 'action'],
            columns,
            pageData: resultData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: resultRes?.meta.total || 1,
            noBaseCol: true,
            filterFields,
          }}
          noCheckbox
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
        />
      </div>
    </>
  );
};

export default SurveyResultManagement;
