import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col,
  Row, Select, Space, Spin,
  Switch,
  Typography,
  message
} from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import ErrorText from 'common/components/ErrorText';
import HeaderPage from 'common/components/HeaderPage';
import ManagementInfo from 'common/components/ManagementInfo';
import ModalConfirm from 'common/components/ModalConfirm';
import SelectFile from 'common/components/SelectFile';
import {
  createKioskService, getAllUserForKioskService, getKioskByIdService, updateKioskService
} from 'common/services/kiosk';
import { CreateKioskParamsTypes, UpdateKioskParamsTypes } from 'common/services/kiosk/types';
import { ROUTE_PATHS, printerOptions } from 'common/utils/constant';
import { delay } from 'common/utils/functions';
import { updateKioskSchema } from 'common/utils/schemas';
import { RenderInputFormField } from 'features/FormDetail/RenderUI/RenderFormElement';

export type ContactProblemFormTypes = {
  code?: string;
  name?: string;
  address?: string;
  logo?: string;
  widthLogo?: number;
  heightLogo?: number;
  image?: string;
  forWeb: boolean;
  printerType?: OptionType;
  userIds?: Array<{
    value: number,
    text: string,
  }>,
};

const defaultValues: ContactProblemFormTypes = {
  code: '',
  name: '',
  printerType: undefined,
  userIds: undefined,
  forWeb: false,
};

const KioskDetail: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  /* Selectors */
  const { defaultWebsiteLanguage, languageOptions } = useAppSelector((state) => state.system);

  /* States */
  const idParams = Number(searchParams.get('id'));
  const localeParams = searchParams.get('locale') || defaultWebsiteLanguage || '';

  const [currentLang, setCurrentLang] = useState<string>(
    localeParams
  );
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [confirm, setConfirm] = useState<string | undefined>(undefined);

  /* React-hook-form */
  const method = useForm<ContactProblemFormTypes>({
    resolver: yupResolver(updateKioskSchema),
    defaultValues,
    reValidateMode: 'onChange',
  });

  const { isDirty } = method.formState;

  const watchForWeb = method.watch('forWeb');
  const codeFieldStatus = method.watch('code');

  /* Queries */
  const { data: kioskByIdData, isLoading } = useQuery(
    ['kiosk-detail', idParams, localeParams],
    () => {
      if (idParams) {
        return getKioskByIdService(idParams);
      }
      return undefined;
    }
  );

  const { data: userKioskData } = useQuery(
    ['userKiosk'],
    () => getAllUserForKioskService()
  );

  const { mutate: updateKioskByIdMutate, isLoading: updateLoading } = useMutation(
    ['kiosk-update'],
    async (params: UpdateKioskParamsTypes) => updateKioskService(params.id, params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(['kiosk-detail', idParams]);
      },
      onError: (error) => {
        if (Array.isArray(error) && error[0].code === 'dimensionInvalid') {
          message.error(error[0]?.message);
        } else {
          message.error(t('message.createError'));
        }
      }
    }
  );

  const { mutate: createKioskByIdMutate, isLoading: createLoading } = useMutation(
    ['kiosk-create'],
    async (params: CreateKioskParamsTypes) => createKioskService(params),
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigate(`${ROUTE_PATHS.KIOSK_MANAGEMENT}`);
      },
      onError: (error) => {
        if (Array.isArray(error) && error[0].code === 'dimensionInvalid') {
          message.error(error[0]?.message);
        } else {
          message.error(t('message.createError'));
        }
      }
    }
  );

  const onSubmit = async (addingFn?: (id: number) => void) => {
    const isValid = await method.trigger();

    const formData = method.getValues();
    if (!watchForWeb) {
      if (!formData.printerType) {
        method.setError('printerType', {
          message: 'Trường này là bắt buộc',
          type: 'required'
        });
        return;
      }
    } else {
      method.clearErrors('printerType');
    }

    if (!isValid) {
      return;
    }

    const userIdsReq = formData.userIds?.map((item) => item.value) || [];

    if (idParams) {
      updateKioskByIdMutate({
        address: formData.address,
        image: formData.image,
        forWeb: formData.forWeb,
        id: idParams,
        translations: {
          ...(localeParams === 'vi' ? {
            vi: {
              kioskData: {
                name: formData.name || ''
              }
            }
          } : {
            en: {
              kioskData: {
                name: formData.name || ''
              }
            }
          })
        },
        userIds: userIdsReq,
        logo: formData.logo ? {
          path: formData.logo
        } : null,
        ...(formData.printerType?.value && { printerType: formData.printerType?.value?.toString() })
      });
    } else {
      createKioskByIdMutate({
        userIds: userIdsReq,
        address: formData.address,
        forWeb: formData.forWeb,
        image: formData.image,
        code: formData.code,
        translations: {
          ...(localeParams === 'vi' ? {
            vi: {
              kioskData: {
                name: formData.name || ''
              }
            }
          } : {
            en: {
              kioskData: {
                name: formData.name || ''
              }
            }
          })
        },
        logo: formData.logo ? {
          path: formData.logo
        } : null,
        ...(formData.printerType?.value && { printerType: formData.printerType?.value?.toString() })
      }, {
        onSuccess: (data) => addingFn && addingFn(data.id),
      });
    }
  };

  const submitForm = async () => {
    await onSubmit();
  };

  const changeLanguageAction = async (lang?: string) => {
    if (lang) {
      setConfirm(undefined);
      setLoadingTemplate(true);
      await delay(1000);
      setCurrentLang(lang as LanguageCodeTypes);
      setSearchParams({
        id: String(idParams),
        locale: lang,
      });
      setLoadingTemplate(false);
    }
  };

  const handleChangeLang = async (lang: LanguageCodeTypes) => {
    if (isDirty) {
      setConfirm(lang);
    } else changeLanguageAction(lang);
  };

  /* Effects */
  useEffect(() => {
    if (kioskByIdData) {
      const objDefault: ContactProblemFormTypes = {
        name: (kioskByIdData.translations as any)?.[currentLang]?.name || '',
        code: kioskByIdData.kioskData.code,
        address: kioskByIdData.kioskData.address,
        logo: kioskByIdData.kioskData.logo?.path || '',
        widthLogo: kioskByIdData.kioskData.logo?.width || 0,
        heightLogo: kioskByIdData.kioskData.logo?.height || 0,
        image: kioskByIdData.kioskData.image,
        userIds: kioskByIdData?.assignedUsers?.map((item) => ({
          text: item.name,
          value: item.id,
        })),
        forWeb: kioskByIdData.kioskData.forWeb,
        printerType: printerOptions.find((p) => p.value === kioskByIdData.kioskData.printerType)
      };
      method.reset(objDefault);
    } else {
      method.reset(defaultValues);
    }
  }, [kioskByIdData, method, currentLang]);

  const forLogo = useMemo(() => {
    const path = kioskByIdData?.kioskData?.logo?.path;
    const width = kioskByIdData?.kioskData?.logo?.width;
    const height = kioskByIdData?.kioskData?.logo?.height;
    if (path && width && height) {
      return `&logo=${path}&width=${width}&height=${height}`;
    }
    if (path) {
      return `&logo=${path}`;
    }
    return '';
  }, [kioskByIdData]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={idParams ? t('kioskManagement.titleEdit') : t('kioskManagement.titleCreate')}
        rightHeader={(
          <Space size={16}>
            <Button
              type="primary"
              disabled={(idParams && !roleUpdate) || (!idParams && !roleCreate)}
              loading={updateLoading || createLoading}
              onClick={method.handleSubmit(submitForm)}
            >
              <SaveOutlined />
              {t('system.save')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Spin size="large" spinning={updateLoading || isLoading || createLoading || loadingTemplate}>
          <FormProvider {...method}>
            <Row gutter={16}>
              <Col xxl={18} xl={16} lg={16}>
                <Card type="inner">
                  <Row gutter={[16, 16]}>
                    {/* Tên */}
                    <Col span={24}>
                      {/* name */}
                      <RenderInputFormField
                        required
                        propertyKey="name"
                      />
                    </Col>
                    <Col span={24}>
                      <div>
                        <Typography.Text strong>
                          {t('kioskManagement.forweb')}
                        </Typography.Text>
                        <Controller
                          name="forWeb"
                          render={({ field }) => (
                            <div>
                              <Switch
                                checked={field.value}
                                onChange={field.onChange}
                              />
                            </div>
                          )}
                        />
                      </div>
                    </Col>
                    <Col span={12}>
                      {/* code */}
                      <RenderInputFormField required propertyKey="code" disabled={!!idParams} />
                    </Col>
                    <Col span={12}>
                      {/* Printer type */}
                      <Typography.Text strong>
                        {t('kioskManagement.printerType')}
                        {' '}
                      </Typography.Text>
                      {
                        !watchForWeb && (
                          <Typography.Text strong type="danger">
                            *
                          </Typography.Text>
                        )
                      }
                      <Controller
                        name="printerType"
                        control={method.control}
                        // defaultValue={[]}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <>
                            <Select
                              className="u-mt-8"
                              size="large"
                              style={{ width: '100%' }}
                              labelInValue
                              value={value}
                              onChange={onChange}
                              placeholder={`${t('system.select')} printer`}
                            >
                              {printerOptions?.map((ele, idx) => (
                                <Select.Option
                                  key={`printer-option-${idx.toString()}`}
                                  value={ele.value}
                                >
                                  {ele.label}
                                </Select.Option>
                              ))}
                            </Select>
                            {
                              error && (
                                <ErrorText>
                                  {error.message}
                                </ErrorText>
                              )
                            }
                          </>
                        )}
                      />
                    </Col>
                    <Col span={12} style={{ display: watchForWeb ? 'flex' : 'none' }}>
                      <div>
                        <Typography.Text style={{ visibility: 'hidden' }}>hidden</Typography.Text>
                        <div>
                          <Button
                            type="primary"
                            disabled={!codeFieldStatus}
                            onClick={() => window.open(`${window.location.origin + ROUTE_PATHS.GENERATE_QRCODE}?code=${method.getValues('code')}${forLogo}`, '_blank')}
                          >
                            {t('kioskManagement.generateQRButton')}
                          </Button>
                        </div>
                      </div>
                      <div style={{ paddingLeft: '20px' }}>
                        <Typography.Text>
                          {t('kioskManagement.noteDescriptionQR')}
                          <li style={{ paddingLeft: '12px' }}>{t('kioskManagement.noteWinQR')}</li>
                          <li style={{ paddingLeft: '12px' }}>{t('kioskManagement.noteMacQR')}</li>
                        </Typography.Text>
                      </div>
                    </Col>
                    {
                      watchForWeb && (
                        <Col>
                          <Controller
                            name="logo"
                            render={({
                              field: { value, onChange },
                            }) => (
                              <SelectFile
                                value={value}
                                name="logo"
                                titleName="logo"
                                handleSelect={(url) => {
                                  onChange(url);
                                }}
                                handleDelete={() => {
                                  onChange('');
                                }}
                                title={(
                                  <Typography.Text strong>
                                    Logo
                                    {' '}
                                    (lưu ý: khuyến khích kích thước tối đa 50x50)
                                  </Typography.Text>
                                )}
                              />
                            )}
                          />
                        </Col>
                      )
                    }
                    <Col>
                      {/* Address */}
                      <RenderInputFormField propertyKey="address" />
                    </Col>
                    <Col>
                      {/* Image */}
                      <Controller
                        name="image"
                        render={({
                          field: { value, onChange },
                          fieldState
                        }) => (
                          <>
                            <SelectFile
                              value={value}
                              name="image"
                              titleName="thumbnailTitle"
                              altName="thumbnailAlt"
                              handleSelect={(url) => {
                                onChange(url);
                              }}
                              handleDelete={() => onChange(undefined)}
                              title={(
                                <Typography.Text strong>
                                  {t('newsDetail.thumbnail')}
                                  {' '}
                                </Typography.Text>
                              )}
                            />
                            {fieldState.error && (
                              <span
                                className="a-input_errorMessage"
                              >
                                {fieldState.error.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Col>
                    <Col>
                      {/* Users */}
                      <Typography.Text strong>
                        {t('kioskManagement.user')}
                      </Typography.Text>
                      <Controller
                        name="userIds"
                        // defaultValue={[]}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <>
                            <Select
                              className="u-mt-8"
                              size="large"
                              style={{ width: '100%' }}
                              labelInValue
                              value={value}
                              onChange={(newValue) => {
                                onChange([...newValue].map((ele) => ({
                                  text: ele.label,
                                  value: ele.value
                                })));
                              }}
                              mode="multiple"
                              allowClear
                              placeholder={`${t('system.select')} user`}
                            >
                              {userKioskData?.map((ele) => (
                                <Select.Option
                                  key={`option-${ele.userData.id.toString()}`}
                                  value={ele.userData.id}
                                >
                                  {ele.userData.name}
                                </Select.Option>
                              ))}
                            </Select>
                            {
                              error && (
                                <ErrorText>
                                  {error.message}
                                </ErrorText>
                              )
                            }
                          </>
                        )}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xxl={6} xl={8} lg={8}>
                <ManagementInfo
                  createdDate={kioskByIdData ? moment(kioskByIdData.kioskData?.createdAt).fromNow() : ''}
                  createdBy={kioskByIdData?.creator.name || ''}
                  lastUpdated={kioskByIdData ? moment(kioskByIdData.kioskData?.updatedAt).fromNow() : ''}
                  lastUpdatedBy={kioskByIdData?.updater.name || ''}
                  languageList={languageOptions}
                  currentLang={currentLang}
                  handleChangeLang={
                    (value) => value && handleChangeLang(value as LanguageCodeTypes)
                  }
                />
              </Col>
            </Row>
          </FormProvider>
        </Spin>
        <ModalConfirm
          isShow={!!confirm}
          handleCancel={() => setConfirm(undefined)}
          handleConfirm={() => changeLanguageAction(confirm)}
          handleClose={() => setConfirm(undefined)}
        >
          {t('message.confirmSkipInfo')}
        </ModalConfirm>
      </div>
    </>
  );
};

export default KioskDetail;
