import { SurveyChartResponseDataTypes } from 'common/services/dashboard/types';

enum EKidneyRiskCode {
  proteinuriaAndCdk1 = 'Có đạm niệu và bệnh thận mạn giai đoạn 1',
  proteinuriaAndCdk2 = 'Có đạm niệu và bệnh thận mạn giai đoạn 2',
  proteinuriaAndCdk3 = 'Có đạm niệu và bệnh thận mạn giai đoạn 3',
  proteinuriaAndCdk3a = 'Có đạm niệu và bệnh thận mạn giai đoạn 3A',
  proteinuriaAndCdk3b = 'Có đạm niệu và bệnh thận mạn giai đoạn 3B',
  proteinuriaAndCdk4 = 'Có đạm niệu và bệnh thận mạn giai đoạn 4',
  proteinuriaAndCdk5 = 'Có đạm niệu và bệnh thận mạn giai đoạn 5',
  proteinuriaAndCdk45 = 'Có đạm niệu và bệnh thận mạn giai đoạn 4,5',
  cdk1 = 'Bệnh thận mạn giai đoạn 1',
  cdk2 = 'Bệnh thận mạn giai đoạn 2',
  cdk3 = 'Bệnh thận mạn giai đoạn 3',
  cdk3a = 'Bệnh thận mạn giai đoạn 3A',
  cdk3b = 'Bệnh thận mạn giai đoạn 3B',
  cdk4 = 'Bệnh thận mạn giai đoạn 4',
  cdk5 = 'Bệnh thận mạn giai đoạn 5',
  cdk45 = 'Bệnh thận mạn giai đoạn 4,5',
  missing = 'Chưa đủ thông tin để đánh giá yếu tố nguy cơ thận của bạn. Vui lòng hỏi bác sĩ để được tư vấn thêm.',
  none = 'Không có yếu tố nguy cơ thận',
  proteinuria = 'Có đạm niệu',
  missingKidneyRisk = 'Chưa đủ thông tin đánh giá yếu tố nguy cơ thận',
  missingHeartRisk = 'Chưa đủ thông tin đánh giá yếu tố nguy cơ tim mạch',
  na = 'N/a',
  empty = 'Trống',
  unknow = 'Không biết',
  low = 'Thấp',
  medium = 'Trung bình',
  high = 'Cao',
  veryHigh = 'Rất cao',
  highAndVeryHigh = 'Cao và rất cao',
  lowAndMedium = 'Thấp và trung bình',
  matrixAndLessAge = 'Không đánh giá dưới 40 tuổi',
  twentyPercent = 'Có 20% nguy cơ bệnh thận mạn.',
  zeroPercent = 'Không có nguy cơ bệnh thận mạn hiện tại.'
}

function colorGen() {
  const generateColor = Math.floor(Math.random() * 256);
  return generateColor;
}

export function translationSick(result?: string) {
  switch (result) {
    case 'proteinuriaAndCdk1':
      return EKidneyRiskCode.proteinuriaAndCdk1;
    case 'proteinuriaAndCdk2':
      return EKidneyRiskCode.proteinuriaAndCdk2;
    case 'proteinuriaAndCdk3':
      return EKidneyRiskCode.proteinuriaAndCdk3;
    case 'proteinuriaAndCdk3a':
      return EKidneyRiskCode.proteinuriaAndCdk3a;
    case 'proteinuriaAndCdk3b':
      return EKidneyRiskCode.proteinuriaAndCdk3b;
    case 'proteinuriaAndCdk4':
      return EKidneyRiskCode.proteinuriaAndCdk4;
    case 'proteinuriaAndCdk45':
      return EKidneyRiskCode.proteinuriaAndCdk45;
    case 'proteinuriaAndCdk5':
      return EKidneyRiskCode.proteinuriaAndCdk5;
    case 'cdk1':
      return EKidneyRiskCode.cdk1;
    case 'cdk2':
      return EKidneyRiskCode.cdk2;
    case 'cdk3':
      return EKidneyRiskCode.cdk3;
    case 'cdk3a':
      return EKidneyRiskCode.cdk3a;
    case 'cdk3b':
      return EKidneyRiskCode.cdk3b;
    case 'cdk4':
      return EKidneyRiskCode.cdk4;
    case 'cdk5':
      return EKidneyRiskCode.cdk5;
    case 'cdk45':
      return EKidneyRiskCode.cdk45;
    case 'missing':
      return EKidneyRiskCode.missing;
    case 'none':
      return EKidneyRiskCode.none;
    case 'proteinuria':
      return EKidneyRiskCode.proteinuria;
    case 'missingHeartRisk':
      return EKidneyRiskCode.missingHeartRisk;
    case 'missingKidneyRisk':
      return EKidneyRiskCode.missingKidneyRisk;
    case 'na':
      return EKidneyRiskCode.na;
    case 'empty':
      return EKidneyRiskCode.empty;
    case 'unknow':
      return EKidneyRiskCode.unknow;
    case 'low':
      return EKidneyRiskCode.low;
    case 'medium':
      return EKidneyRiskCode.medium;
    case 'high':
      return EKidneyRiskCode.high;
    case 'highAndVeryHigh':
      return EKidneyRiskCode.highAndVeryHigh;
    case 'veryHigh':
      return EKidneyRiskCode.veryHigh;
    case 'lowAndMedium':
      return EKidneyRiskCode.lowAndMedium;
    case 'matrixAndLessAge':
      return EKidneyRiskCode.matrixAndLessAge;
    case 'twentyPercent':
      return EKidneyRiskCode.twentyPercent;
    case 'zeroPercent':
      return EKidneyRiskCode.zeroPercent;
    default:
      return result || '';
  }
}

export type DataSetChart = {
  labels: string[],
  datasets: Array<{
    type?: string,
    label: string,
    backgroundColor?: string,
    data: number[],
    borderColor?: string,
    yAxisID?: string,
    stack?: string,
  }>
};

const convertDataSet = (
  type: 'YNTCThan' | 'YNTCTimMach',
  dataChart?: SurveyChartResponseDataTypes[],
  selectedFilter?: string[],
  isOlData?: boolean,
): DataSetChart | undefined => {
  if (dataChart && dataChart.length > 0) {
    // Label
    const kiosk = selectedFilter && selectedFilter?.length > 0
      ? dataChart.filter((item) => selectedFilter.includes(isOlData ? (item.kiosk || '') : (item.kioskGroup || '')))
        .map((item) => (isOlData ? (item.kiosk || '') : (item.kioskGroup || ''))) : dataChart.map((item) => (isOlData ? (item.kiosk || '') : (item.kioskGroup || '')));
    const uniqueKioskLabels = kiosk?.filter((c, index) => kiosk.indexOf(c) === index);

    // label dataset
    let labelDataset: (string | undefined)[] = [];
    if (type === 'YNTCThan') {
      labelDataset = dataChart.map((item) => item.kidneyRisk);
    } else if (type === 'YNTCTimMach') {
      labelDataset = dataChart.map((item) => item.heartRisk);
    }

    const uniqueLabelDataset = labelDataset.filter((c, index) => labelDataset.indexOf(c) === index)
      .map((item) => (item || '-'));

    const dataSetGroup: { label: string, data: SurveyChartResponseDataTypes[] }[] = [];

    uniqueKioskLabels.forEach((kioskLabel) => {
      const obj: { label: string, data: SurveyChartResponseDataTypes[] } = {
        label: kioskLabel,
        data: []
      };

      const groupKiosks: SurveyChartResponseDataTypes[] = [];
      // get group by kiosk
      dataChart.forEach((item) => {
        if ((!isOlData && item.kioskGroup === kioskLabel)
          || (isOlData && item.kiosk === kioskLabel)) {
          groupKiosks.push({
            ...item,
            kidneyRisk: type === 'YNTCThan' && item.kidneyRisk ? item.kidneyRisk : '-',
            heartRisk: type === 'YNTCTimMach' && item.heartRisk ? item.heartRisk : '-',
          });
        }
      });

      // check item still missing in groupKiosks
      const notItemInsideGroup = uniqueLabelDataset.filter((x) => {
        let itemSame;
        if (type === 'YNTCThan') {
          itemSame = groupKiosks.find((group) => {
            const groupCondition = group.kidneyRisk ? group.kidneyRisk : '-';
            return groupCondition === x;
          });
        } else if (type === 'YNTCTimMach') {
          itemSame = groupKiosks.find((group) => {
            const groupCondition = group.heartRisk ? group.heartRisk : '-';
            return groupCondition === x;
          });
        }
        if (!itemSame) {
          return x;
        }

        return undefined;
      });

      // add notItemInsideGroup into groupKiosks
      if (notItemInsideGroup && notItemInsideGroup.length > 0) {
        notItemInsideGroup.forEach((itemInside) => {
          if (type === 'YNTCThan') {
            if (isOlData) {
              groupKiosks.push({
                kiosk: obj.label,
                kidneyRisk: itemInside,
                rowsCount: 0
              });
            } else {
              groupKiosks.push({
                kioskGroup: obj.label,
                kidneyRisk: itemInside,
                rowsCount: 0
              });
            }
          } else if (type === 'YNTCTimMach') {
            if (isOlData) {
              groupKiosks.push({
                kiosk: obj.label,
                heartRisk: itemInside,
                rowsCount: 0
              });
            } else {
              groupKiosks.push({
                kioskGroup: obj.label,
                heartRisk: itemInside,
                rowsCount: 0
              });
            }
          }
        });
      }

      // add per item to data include item nothing
      uniqueLabelDataset.forEach((lbDataSet) => {
        groupKiosks.forEach((group) => {
          const groupKidneyRiskCondition = group.kidneyRisk ? group.kidneyRisk : '-';
          const groupHeartRiskCondition = group.heartRisk ? group.heartRisk : '-';
          if (lbDataSet === groupKidneyRiskCondition && type === 'YNTCThan') {
            obj.data.push({
              ...group,
              kidneyRisk: group.kidneyRisk || '-',
            });
          }
          if (lbDataSet === groupHeartRiskCondition && type === 'YNTCTimMach') {
            obj.data.push({
              ...group,
              heartRisk: group.heartRisk || '-',
            });
          }
        });
      });
      dataSetGroup.push(obj);
    });

    const dataSetFinal: {
      type: string,
      label: string,
      backgroundColor: string,
      data: number[],
    }[] = [];

    uniqueLabelDataset.forEach((lbDataset, idx) => {
      const dataSet: number[] = [];
      dataSetGroup.forEach((group) => {
        const groupKidneyRiskCondition = group.data[idx]?.kidneyRisk || '-';
        const groupHeartRiskCondition = group.data[idx]?.heartRisk || '-';
        if (lbDataset === groupKidneyRiskCondition || lbDataset === groupHeartRiskCondition) {
          dataSet.push(group.data[idx].rowsCount);
        }
      });

      dataSetFinal.push({
        type: 'bar',
        label: translationSick(lbDataset || ''),
        data: dataSet,
        backgroundColor: `rgb(${colorGen()},${colorGen()},${colorGen()})`,
      });
    });

    return {
      labels: uniqueKioskLabels,
      datasets: dataSetFinal
    };
  }
  return undefined;
};

export const convertCounterDataSet = (
  textTotal: string,
  dataChart?: SurveyChartResponseDataTypes[],
  selectedFilter?: string[],
  isOlData?: boolean,
): DataSetChart | undefined => {
  if (dataChart && dataChart.length > 0) {
    // Label
    const labelHorizontal = selectedFilter && selectedFilter?.length > 0
      ? dataChart.filter((item) => selectedFilter.includes(isOlData ? (item.kiosk || '') : (item.kioskGroup || '')))
        .map((item) => `${item.month}/${item.year}`)
      : dataChart.map((item) => `${item.month}/${item.year}`);
    const uniqueLabelHorizontal = labelHorizontal?.filter(
      (c, index) => labelHorizontal.indexOf(c) === index
    );

    // label dataset
    const labelDataset = selectedFilter && selectedFilter?.length > 0
      ? dataChart.filter((item) => selectedFilter.includes(isOlData ? (item.kiosk || '') : (item.kioskGroup || '')))
        .map((item) => (isOlData ? item.kiosk : item.kioskGroup))
      : dataChart.map((item) => (isOlData ? item.kiosk : item.kioskGroup));
    const uniqueLabelDataset = labelDataset.filter((c, index) => labelDataset.indexOf(c) === index)
      .map((item) => (item || '-'));

    const dataSetGroup: { label: string, data: SurveyChartResponseDataTypes[] }[] = [];

    uniqueLabelHorizontal.forEach((lbHorizontal) => {
      const obj: { label: string, data: SurveyChartResponseDataTypes[] } = {
        label: lbHorizontal,
        data: []
      };

      const groupKiosks: SurveyChartResponseDataTypes[] = [];
      // get group by kiosk
      dataChart.forEach((item) => {
        const mmYYYY = `${item.month}/${item.year}`;
        if (mmYYYY === lbHorizontal) {
          groupKiosks.push(item);
        }
      });

      // check item still missing in groupKiosks
      const notItemInsideGroup = uniqueLabelDataset.filter((x) => {
        const itemSame = groupKiosks.find((group) => (isOlData
          ? group.kiosk === x : group.kioskGroup === x));
        if (!itemSame) {
          return x;
        }

        return undefined;
      });

      // add notItemInsideGroup into groupKiosks
      if (notItemInsideGroup && notItemInsideGroup.length > 0) {
        notItemInsideGroup.forEach((itemInside) => {
          if (isOlData) {
            groupKiosks.push({
              kiosk: itemInside,
              rowsCount: 0
            });
          } else {
            groupKiosks.push({
              kioskGroup: itemInside,
              rowsCount: 0
            });
          }
        });
      }

      // add per item to data include item nothing
      uniqueLabelDataset.forEach((lbDataSet) => {
        groupKiosks.forEach((group) => {
          if (lbDataSet === group.kioskGroup || (isOlData && lbDataSet === group.kiosk)) {
            obj.data.push(group);
          }
        });
      });
      dataSetGroup.push(obj);
    });

    // Total
    const dataSetTotal: number[] = [];

    dataSetGroup.forEach((group) => {
      let totalValue = 0;
      group.data.forEach((item) => {
        totalValue += item.rowsCount;
      });
      dataSetTotal.push(totalValue);
    });

    const dataSetFinal: {
      type?: string,
      label: string,
      backgroundColor: string,
      data: number[],
    }[] = [];

    uniqueLabelDataset.forEach((lbDataset, idx) => {
      const dataSet: number[] = [];
      dataSetGroup.forEach((group) => {
        if (group.data[idx]?.kioskGroup && lbDataset === group.data[idx]?.kioskGroup
          || isOlData && group.data[idx]?.kiosk && lbDataset === group.data[idx]?.kiosk) {
          dataSet.push(group.data[idx].rowsCount);
        }
      });

      dataSetFinal.push({
        type: 'bar' as const,
        label: lbDataset || '',
        data: dataSet,
        backgroundColor: `rgb(${colorGen()},${colorGen()},${colorGen()})`,
      });
    });

    const totalColor = `rgb(${colorGen()},${colorGen()},${colorGen()})`;

    return {
      labels: uniqueLabelHorizontal,
      datasets: [{
        label: textTotal,
        data: dataSetTotal,
        backgroundColor: totalColor,
        borderColor: totalColor,
        type: 'line' as const,
      }, ...dataSetFinal],
    };
  }
  return undefined;
};

export default convertDataSet;
