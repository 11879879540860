import { Typography, Card, Collapse } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ResultDataDetailTypes } from 'common/services/survey/types';
import { translationSick } from 'extends/Charts/helper';

interface SurveyDetailInfoProps {
  data: ResultDataDetailTypes;
}

interface SurveyDetailItemProps {
  title: string;
  content: string;
}

const SurveyDetailItem: React.FC<SurveyDetailItemProps> = ({
  title,
  content
}) => (
  <div className="surveyDetail_item" style={{ marginBottom: 4 }}>
    <Collapse defaultActiveKey={['1']}>
      <Collapse.Panel header={title} key="1">
        <Typography.Text>
          {content}
        </Typography.Text>
      </Collapse.Panel>
    </Collapse>
  </div>
);

const SurveyDetailContent: React.FC<SurveyDetailItemProps> = ({
  title,
  content,
}) => (
  <div className="surveyDetail_item" style={{ marginBottom: 4 }}>
    <Typography.Text strong>
      {title}
      {': '}
    </Typography.Text>
    <Typography.Text>{content}</Typography.Text>
  </div>
);

const CholesterolCode: { [key: string]: string } = {
  lowAndMedium: 'Mỡ máu: duy trì LDL-C < 2,6mmol/L (100mg/dL).',
  medium: 'Mỡ máu: duy trì LDL-C < 2,6mmol/L (100mg/dL).',
  high: 'Mỡ máu: duy trì LDL-C < 1,8mmol/L (70mg/dL) và giảm >= 50% so với mức ban đầu.',
  veryHigh: 'Mỡ máu: duy trì LDL-C < 1,4mmol/L (55mg/dL) và giảm >= 50% so với mức ban đầu.',
};

const SurveyDetailInfo: React.FC<SurveyDetailInfoProps> = ({
  data,
}) => {
  const { t } = useTranslation();

  const renderContentItem = useCallback((text: string) => {
    if (typeof text === 'string') {
      return t(`system.${text}`);
    }
    return text;
  }, [t]);

  return (
    <Card>
      <Card>
        <SurveyDetailContent title={t('surveyResult.source')} content={data.resultData.kioskCode ? data.resultData.kioskCode : t('kioskManagement.website')} />
        <SurveyDetailContent title={t('surveyResult.heartRiskResult')} content={translationSick(data.resultData.heartRiskCode)} />
        <SurveyDetailContent title={t('surveyResult.heartScoreResult')} content={String(data.resultData.heartRiskScore || '')} />
        <SurveyDetailContent title={t('surveyResult.kidneyRiskResult')} content={translationSick(data.resultData.kidneyRiskCode)} />
        <SurveyDetailContent title={t('surveyResult.cholesterol')} content={CholesterolCode[data.resultData.heartRiskCode] || ''} />
      </Card>
      <div className="surveyDetail_list" style={{ marginTop: 16 }}>
        {data?.detail.map((item, idx) => (
          <SurveyDetailItem
            title={item.question}
            content={renderContentItem(item.result)}
            key={`result-item-${String(idx)}`}
          />
        ))}
      </div>
    </Card>
  );
};
export default SurveyDetailInfo;
