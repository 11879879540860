import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Typography,
  message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import { deleteKioskService, deleteKioskTranslationService, getAllKioskService } from 'common/services/kiosk';
import { ROUTE_PATHS, printerOptions } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

export type KioskDataTypes = {
  id: number;
  title: string;
  code?: string;
  printerType: string;
  createdAt: string;
  updatedAt: string;
  locale: {
    [lang: string]: {
      name: string;
      id: number;
    }
  } | null;
};

const KioskManagement: React.FC<ActiveRoles> = ({
  roleCreate,
  roleDelete,
  roleUpdate
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  /* Selectors */
  const {
    defaultPageSize,
    defaultWebsiteLanguage
  } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  /* Queries */
  const { data: kiosks, isLoading } = useQuery(
    ['getAllKiosk', keyword, currentPage, currentView],
    () => getAllKioskService({
      keyword, page: currentPage, limit: currentView
    }),
  );

  const { mutate: deleteKioskMutate, isLoading: deleteNewsLoading } = useMutation(
    ['kiosk-delete'],
    async (ids: number[]) => deleteKioskService({ ids }),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(['getAllKiosk']);
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }
    }
  );

  const {
    mutate: deleteKioskTranslationMutate,
    isLoading: deleteKioskTranslationLoading
  } = useMutation(
    ['kiosk-translation-delete'],
    async (ids: number[]) => deleteKioskTranslationService({ ids }),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(['getAllKiosk']);
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }

    }
  );

  const handleDelete = useCallback((data: KioskDataTypes[], lang?: string) => {
    switch (lang) {
      case 'allRow':
      case 'all': {
        deleteKioskMutate(data.map((ele) => ele.id));
        break;
      }
      default: {
        if (lang) {
          const localeId = data?.[0].locale?.[lang]?.id;
          if (localeId) {
            deleteKioskTranslationMutate([localeId]);
          }
        }
        break;
      }
    }
  }, [deleteKioskMutate, deleteKioskTranslationMutate]);

  /* Variables */
  const columns: ColumnsType<KioskDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: KioskDataTypes, b: KioskDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: KioskDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tiêu đề
    {
      title: t('system.title'),
      dataIndex: 'title',
      key: 'title',
      sorter: {
        compare: (a: KioskDataTypes, b: KioskDataTypes) => a.title.localeCompare(b.title)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: KioskDataTypes) => (
        <Typography.Text>
          {data.title}
        </Typography.Text>
      ),
    },
    {
      title: t('system.code'),
      dataIndex: 'code',
      key: 'code',
      render: (_: string, data: KioskDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
          onClick={() => navigate(`${ROUTE_PATHS.KIOSK_DETAIL}?id=${data.id}&locale=${defaultWebsiteLanguage}`)}
        >
          {data.code}
        </Typography.Text>
      ),
    },
    // --- Ngày tạo
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: KioskDataTypes, b: KioskDataTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: any) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: KioskDataTypes, b: KioskDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: any) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    // Máy in
    {
      title: t('kioskManagement.printerType'),
      dataIndex: 'printerType',
      key: 'printerType',
      render: (_: string, data: any) => (
        <Typography.Text>
          {data.printerType}
        </Typography.Text>
      ),
    },
  ];

  const kioskData = useMemo(() => (kiosks?.data?.map((val) => ({
    id: val.kioskData.id,
    title: val.translations?.[defaultWebsiteLanguage || 'vi']?.name || '',
    code: val.kioskData.code,
    printerType: printerOptions.find((p) => p.value === val.kioskData.printerType)?.label || '',
    createdAt: val.kioskData.createdAt,
    updatedAt: val.kioskData.updatedAt,
    locale: val.translations ? Object.fromEntries(
      Object
        .entries(val.translations)
        .map(([k, o]) => [k, { ...o, id: val.translations?.[k].id, }])
    ) : {}
  })) || []), [kiosks, defaultWebsiteLanguage]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.kiosk')}
        rightHeader={(
          <Button
            type="primary"
            disabled={!roleCreate}
            onClick={() => roleCreate && navigate(`${ROUTE_PATHS.KIOSK_DETAIL}`)}
          >
            <PlusOutlined />
            {t('system.create')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleDelete={handleDelete}
          handleSearch={setKeyword}
          isLoading={isLoading || deleteNewsLoading || deleteKioskTranslationLoading}
          handleEditPage={(id, _, locale) => {
            navigate(`${ROUTE_PATHS.KIOSK_DETAIL}?id=${id}&locale=${locale}`);
          }}
          handleCreatePage={(id, _, locale) => {
            navigate(`${ROUTE_PATHS.KIOSK_DETAIL}?id=${id}&locale=${locale}`);
          }}
          roles={{
            roleCreate,
            roleDelete,
            roleUpdate
          }}
          tableProps={{
            initShowColumns: ['id', 'title', 'code', 'printerType'],
            columns,
            pageData: kioskData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: kiosks?.meta.total || 1,
          }}
        />
      </div>
    </>
  );
};

export default KioskManagement;
