import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import { getAllQuestionService } from 'common/services/survey';
import { QuestionDataResponseTypes } from 'common/services/survey/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

export type QuestionDataTypes = {
  id: number;
  code: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  locale: QuestionDataResponseTypes['translations'];
};

const SurveyQuestionManagement: React.FC<ActiveRoles> = ({
  roleView,
  roleUpdate,
  roleCreate,
  roleDelete
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();

  /* Selectors */
  const {
    defaultPageSize,
  } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');

  /* Queries */
  const { data: questions, isLoading } = useQuery(
    ['getAllQuestions', keyword, currentPage, currentView],
    () => getAllQuestionService({
      keyword, page: currentPage, limit: currentView
    }),
  );

  /* Variables */
  const columns: ColumnsType<QuestionDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: QuestionDataTypes, b: QuestionDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: QuestionDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('system.title'),
      dataIndex: 'title',
      key: 'title',
      render: (_: string, data: any) => (
        <Typography.Text
          onClick={() => roleView && navigate(`${ROUTE_PATHS.SURVEY_QUESTION_DETAIL}?id=${data.id}&locale=${data.locale.vi ? 'vi' : 'en'}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.title}
        </Typography.Text>
      ),
    },
    {
      title: t('system.code'),
      dataIndex: 'code',
      key: 'code',
      render: (_: string, data: any) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.code}
        </Typography.Text>
      ),
    },
    // --- Ngày tạo
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: QuestionDataTypes, b: QuestionDataTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: any) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: QuestionDataTypes, b: QuestionDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: any) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
  ];

  const questionData: QuestionDataTypes[] = useMemo(() => (
    questions?.data.filter((item) => !['question-000008', 'question-000009', 'question-000010', 'question-000011', 'question-000011-00000n'].includes(item.questionData.code))
      ?.map((val): QuestionDataTypes => ({
        id: val.questionData.id || 0,
        code: val.questionData.code || '',
        title: val.translations?.vi ? val.translations?.vi.title : val.translations?.en.title,
        createdAt: val.questionData.createdAt || '',
        updatedAt: val.questionData.updatedAt || '',
        locale: Object.fromEntries(
          Object
            .entries(val.translations)
            .map(([k, o]) => [k, { ...o, id: val.questionData.id }])
        )
      })) || []), [questions]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.surveyQuestion')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleSearch={setKeyword}
          isLoading={isLoading}
          handleEditPage={(id, _, locale) => {
            navigate(`${ROUTE_PATHS.SURVEY_QUESTION_DETAIL}?id=${id}&locale=${locale}`);
          }}
          roles={{
            roleCreate,
            roleDelete,
            roleUpdate
          }}
          tableProps={{
            initShowColumns: ['id', 'title'],
            columns,
            pageData: questionData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: questions?.meta.total || 1,
            noDeleteLanguage: true,
            noDelete: true,
          }}
        />
      </div>
    </>
  );
};

export default SurveyQuestionManagement;
