import { SaveOutlined } from '@ant-design/icons';
import {
  Button, Card, message, Space, Typography, Upload
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, {
  useEffect, useState
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import Input from 'common/components/Input';
import SelectFile from 'common/components/SelectFile';
import { getSystemGeneralService, updateSystemGeneralService } from 'common/services/systems';
import { UpdateSystemGeneralParams } from 'common/services/systems/types';
import { getImageURL } from 'common/utils/functions';

type SystemGeneralForm = {
  fileFavicon?: File;
  headerLogo: string;
  gaIds?: string;
  gtmIds?: string;
};

const GeneralSM: React.FC<{ canEdit: boolean }> = ({ canEdit }) => {
  /* Hooks */
  const { t } = useTranslation();

  /* States */
  const [fileList, setFileList] = useState<Array<UploadFile>>([]);

  /* React-hooks-form */
  const method = useForm<SystemGeneralForm>({
    mode: 'onSubmit',
    defaultValues: {
      fileFavicon: undefined,
      headerLogo: undefined,
      gaIds: undefined,
      gtmIds: undefined,
    },
  });

  /* Queries */
  const {
    data: generalData,
  } = useQuery(
    ['systemsManagement-general', 'list'],
    () => getSystemGeneralService(),
  );

  const { mutate: editGeneralMutate, isLoading: isEditLoading } = useMutation(
    ['systemsManagement-general', 'edit'],
    async (params: UpdateSystemGeneralParams) => updateSystemGeneralService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: () => {
        message.error(t('message.updateError'));
      }

    }
  );

  /* Functions */
  const handleSubmit = (data: SystemGeneralForm) => {
    editGeneralMutate({
      ...data,
      gaIds: data?.gaIds?.split(','),
      gtmIds: data?.gtmIds?.split(','),
    });
  };

  /* Effects */
  useEffect(() => {
    if (generalData) {
      method.reset({
        headerLogo: generalData.headerLogo || '',
        gaIds: String(generalData?.gaIds),
        gtmIds: String(generalData?.gtmIds),
      });
      setFileList([{
        uid: '-1',
        name: generalData?.favicon?.split('/').slice(-1)[0] || 'favicon.ico',
        url: getImageURL(generalData?.favicon),
        thumbUrl: getImageURL(generalData?.favicon),
      }]);
    }
  }, [generalData, method]);

  return (
    <div className="p-system_general">
      <FormProvider<SystemGeneralForm> {...method}>
        <form noValidate>
          <Card type="inner">
            <Space className="p-system_general_space" direction="vertical" size={16}>
              <Controller
                name="fileFavicon"
                render={({ field: { onChange } }) => (
                  <Upload
                    listType="picture"
                    beforeUpload={() => false}
                    onChange={(info) => {
                      onChange(info.fileList?.[0]?.originFileObj);
                      setFileList([...info.fileList]);
                    }}
                    onRemove={() => onChange(undefined)}
                    maxCount={1}
                    fileList={fileList}
                    accept="image/x-icon"
                  >
                    <Button
                      type="default"
                    >
                      {t('systemManagement.uploadFavicon')}
                    </Button>
                  </Upload>
                )}
              />
              <div className="p-editPageTemplate_input">
                <Controller
                  name="headerLogo"
                  render={({
                    field: { value, onChange },
                    fieldState
                  }) => (
                    <>
                      <SelectFile
                        value={value}
                        name="headerLogo"
                        handleSelect={(url) => {
                          onChange(url);
                        }}
                        handleDelete={() => onChange(undefined)}
                        title={(
                          <>
                            <Typography.Text strong>
                              {t('systemManagement.headerLogo')}
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                          </>
                        )}
                      />
                      {fieldState.error && (
                        <span
                          className="a-input_errorMessage"
                        >
                          {fieldState.error.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="p-editPageTemplate_input">
                <Typography.Text strong>
                  {t('systemManagement.gaId')}
                </Typography.Text>
                <Controller
                  name="gaIds"
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="u-mt-8"
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  )}
                />
              </div>
              <div className="p-editPageTemplate_input">
                <Typography.Text strong>
                  {t('systemManagement.gtmId')}
                </Typography.Text>
                <Controller
                  name="gtmIds"
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="u-mt-8"
                      value={field.value}
                      onChange={field.onChange}
                      size="large"
                    />
                  )}
                />
              </div>
            </Space>
            <div className="p-system_general_submit u-mt-16">
              <Button
                type="primary"
                disabled={!canEdit}
                loading={isEditLoading}
                onClick={method.handleSubmit(handleSubmit)}
              >
                <SaveOutlined />
                {t('system.save')}
              </Button>
            </div>
          </Card>
        </form>
      </FormProvider>
    </div>
  );
};

export default GeneralSM;
