import axiosInstance from '../common/instance';

import {
  CreateKioskGroupParamsTypes,
  CreateKioskParamsTypes,
  GetAllKioskParamsTypes,
  KioskGroupTypes,
  KioskTypes,
  UserKioskTypes
} from './types';

export const getAllKioskService = async (params: GetAllKioskParamsTypes)
  : Promise<APIPaginationResponse<KioskTypes[]>> => {
  const res = await axiosInstance.get('kiosks', { params });
  return res.data;
};

export const createKioskService = async (params: CreateKioskParamsTypes)
  : Promise<{ id: number }> => {
  const res = await axiosInstance.post('kiosks', params);
  return res.data.data;
};

export const updateKioskService = async (id: number, params: CreateKioskParamsTypes)
  : Promise<unknown> => axiosInstance.put(`kiosks/${id}`, params);

export const deleteKioskService = async (params: { ids: number[] })
  : Promise<unknown> => {
  const res = await axiosInstance.delete('kiosks', { data: params });
  return res.data;
};

export const getKioskByIdService = async (id: number)
  : Promise<KioskTypes> => {
  const res = await axiosInstance.get(`kiosks/${id}`);
  return res.data.data;
};

export const getAllUserForKioskService = async ()
  : Promise<UserKioskTypes[]> => {
  const res = await axiosInstance.get('kiosks/users');
  return res.data.data;
};

export const deleteKioskTranslationService = async (params: { ids: number[] }) => {
  const res = await axiosInstance.delete('kiosks/translations', { data: params });
  return res.data;
};

// Kiosk group
export const getAllKioskGroupService = async (params: GetAllKioskParamsTypes)
  : Promise<APIPaginationResponse<KioskGroupTypes[]>> => {
  const res = await axiosInstance.get('kiosk-groups', { params });
  return res.data;
};

export const createKioskGroupService = async (params: CreateKioskGroupParamsTypes)
  : Promise<{ id: number }> => {
  const res = await axiosInstance.post('kiosk-groups', params);
  return res.data.data;
};

export const updateKioskGroupService = async (id: number, params: CreateKioskGroupParamsTypes)
  : Promise<unknown> => axiosInstance.put(`kiosk-groups/${id}`, params);

export const deleteKioskGroupService = async (params: { ids: number[] })
  : Promise<unknown> => {
  const res = await axiosInstance.delete('kiosk-groups', { data: params });
  return res.data;
};

export const getKioskGroupByIdService = async (id: number)
  : Promise<KioskGroupTypes> => {
  const res = await axiosInstance.get(`kiosk-groups/${id}`);
  return res.data.data;
};
