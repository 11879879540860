import {
  Card, Col, Row, Spin
} from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import SurveyDetailInfo from './surveyDetailInfo';

import HeaderPage from 'common/components/HeaderPage';
import ManagementInfo from 'common/components/ManagementInfo';
import { getResultByIdService } from 'common/services/survey';

const SurveyQuestionDetail: React.FC = () => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  /* States */
  const idParams = Number(searchParams.get('id'));

  /* Queries */
  const { data: resultByIdData, isLoading } = useQuery(
    ['result-detail', idParams],
    () => getResultByIdService(idParams),
    {
      enabled: !!idParams,
    }
  );

  return (
    <>
      <HeaderPage
        fixed
        title={t('surveyResult.detail')}
      />
      <div className="t-mainlayout_wrapper">
        <Spin size="large" spinning={isLoading}>
          <Card type="inner">
            {
              resultByIdData && (
                <Row gutter={[16, 16]}>
                  <Col xxl={18} xl={16} lg={16}>
                    <SurveyDetailInfo data={resultByIdData} />
                  </Col>
                  <Col xxl={6} xl={8} lg={8}>
                    <ManagementInfo
                      classNameCustom="u-mt-16"
                      createdDate={resultByIdData.resultData.createdAt ? moment(resultByIdData.resultData.createdAt).fromNow() : ''}
                      createdBy={resultByIdData.creator || 'Admin'}
                      lastUpdated={resultByIdData.resultData.updatedAt ? moment(resultByIdData.resultData.updatedAt).fromNow() : ''}
                      lastUpdatedBy={resultByIdData.updater || ''}
                    />
                  </Col>
                </Row>
              )
            }
          </Card>
        </Spin>
      </div>
    </>
  );
};

export default SurveyQuestionDetail;
