import {
  Col,
  Row,
  Space,
  Typography
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'app/store';
import roles, { getPermission } from 'configs/roles';
import VerticalBarChartV2 from 'extends/Charts/VerticalBarChartV2';

const gutterValue = 16;

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { profileData } = useAppSelector((state) => state.auth);
  const rolesUser = useAppSelector((state) => state.auth.roles);
  // const {
  //   data: topActivityLogsData,
  //   isLoading: loadingTopActivity,
  //   isFetching: fetchingTopActivity,
  // } = useQuery(
  //   'getTopActivityLogs',
  //   getTopActivityLogsService
  // );

  // const topActivityLogs = useMemo(() => {
  //   if (topActivityLogsData) {
  //     return topActivityLogsData.map((val) => {
  //       const { url, params } = handleActivityLink(
  //         val.modelName as ActivityModelType,
  //         val.modelId,
  //         val.actionName,
  //       );
  //       const arrCharName = (val.user?.name || '').match(/\b(\w)/g) || ['N', 'N'];
  //       arrCharName.splice(1, arrCharName.length - 2);

  //       return ({
  //         time: val.createdAt,
  //         name: val.user?.name,
  //         content: `${val.user.name} ${t(`activityLog.${val.actionName}`)}`,
  //         titleDesc: val.modelData?.title || '',
  //         alt: arrCharName.join('').toLocaleUpperCase(),
  //         avatar: val.user.avatar,
  //         bgAvatar: val.user.bgAvatar,
  //         link: {
  //           href: `${url}${params ? `?${params}` : ''}`,
  //           title: `${t(`activityLog.${val.actionName}`)}
  //           ${t(`activityLog.${val.modelName}`)} ${t('activityLog.with')} ID: ${val.modelId}`,
  //         }
  //       });
  //     });
  //   }
  //   return [];
  // }, [t, topActivityLogsData]);

  return (
    <div className="p-home">
      <div className="p-home_userLabel">
        <Typography.Title
          level={1}
          style={{ color: '#002B60' }}
        >
          {`${t('system.welcome')}, ${profileData?.name}`}
        </Typography.Title>
      </div>
      <Space direction="vertical" size={gutterValue} style={{ width: '100%' }}>
        {/* <Row gutter={[gutterValue, gutterValue]}>
          <Col span={24}>
            <Row gutter={[gutterValue, gutterValue]}>
              <Col xxl={12} lg={12} sm={24}>
                <Space direction="vertical" size={gutterValue} style={{ width: '100%' }}>
                  <SubjectCounterList
                    title={t('dashboard.quickAction')}
                  />
                </Space>
                <Card style={{ marginTop: '16px' }}>
                  <PageTracking />
                </Card>
              </Col>
              <Col xxl={12} lg={12} sm={24}>
                <div className="p-home_right">
                  <div className="p-home_activities">
                    <Card
                      type="inner"
                      style={{ height: '100%' }}
                      bodyStyle={{ height: '100%' }}
                    >
                      <ActivityLogs
                        title={t('dashboard.activity')}
                        dataList={topActivityLogs}
                        loading={loadingTopActivity || fetchingTopActivity}
                      />
                    </Card>
                  </div>
                  <div className="p-home_keywords">
                    <TopKeyWords
                      title={t('dashboard.keyword')}
                      dataList={topKeyWordsDummy}
                      handleClick={(id) => console.log(id)}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[gutterValue, gutterValue]}>
          <Col span={24}>
            <Row gutter={[gutterValue, gutterValue]}>
              <Col xxl={12} lg={12} sm={24}>
                <VisitorsCountryContainer />
              </Col>
              <Col xxl={12} lg={12} sm={24}>
                <DeviceTracking
                  title={t('dashboard.device')}
                />
              </Col>
            </Row>
          </Col>
        </Row> */}
        {
          getPermission(rolesUser, roles.REPORT_SURVEY_KIDNEYRISK) && (
            <Row gutter={[gutterValue, gutterValue]}>
              <Col xxl={24}>
                <VerticalBarChartV2
                  title={t('dashboard.titleSurveyKidneyRisk')}
                  typeSchema="YNTCThan"
                  height={110}
                  legendCustomId="legendCustomKidney"
                  isExport
                />
              </Col>
            </Row>
          )
        }
        <Row gutter={[gutterValue, gutterValue]}>
          <Col xxl={12} lg={12} sm={24}>
            {
              getPermission(rolesUser, roles.REPORT_SURVEY_COUNTER) && (
                <VerticalBarChartV2
                  title={t('dashboard.titleCounter')}
                  typeSchema="SLBN"
                  legendCustomId="legendCustomCounter"
                  height={240}
                  isExport
                />
              )
            }
          </Col>
          <Col xxl={12} lg={12} sm={24}>
            {
              getPermission(rolesUser, roles.REPORT_SURVEY_HEARTRISK) && (
                <VerticalBarChartV2
                  title={t('dashboard.titleSurveyHeart')}
                  typeSchema="YNTCTimMach"
                  height={240}
                  legendCustomId="legendCustomHeart"
                  isExport
                />
              )
            }
          </Col>
        </Row>
        {/* <Row gutter={[gutterValue, gutterValue]}>
          <Col xxl={24}>
            <BarChart title={t('dashboard.gaUserTracking')} />
          </Col>
        </Row> */}
      </Space>
    </div>
  );
};

export default Home;
