import axiosInstance from '../common/instance';

import {
  GetAllQuestionParamsTypes,
  QuestionDataResponseTypes,
  QuestionDetailParamsTypes,
  UpdateQuestionParamsTypes,
  GetAllResultParamsTypes,
  ResultDataResponseTypes,
  ResultDataDetailTypes,
} from './types';

export const getAllQuestionService = async (params: GetAllQuestionParamsTypes)
  : Promise<APIPaginationResponse<QuestionDataResponseTypes[]>> => {
  const res = await axiosInstance.get('survey/questions', { params });
  return res.data;
};

export const updateQuestionService = async (id: number, params: UpdateQuestionParamsTypes)
  : Promise<unknown> => axiosInstance.put(`survey/questions/${id}`, params);

export const getQuestionByIdService = async (id: number)
  : Promise<QuestionDetailParamsTypes> => {
  const res = await axiosInstance.get(`survey/questions/${id}`);
  return res.data.data;
};

export const getAllResultService = async (params: GetAllResultParamsTypes)
  : Promise<APIPaginationResponse<ResultDataResponseTypes[]>> => {
  const res = await axiosInstance.get('survey/results', { params });
  return res.data;
};

export const getResultByIdService = async (id: number)
  : Promise<ResultDataDetailTypes> => {
  const res = await axiosInstance.get(`survey/results/${id}`);
  return res.data.data;
};
