import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, message, Row, Space, Spin
} from 'antd';
import moment from 'moment';
import React, {
  useEffect, useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import ComponentQuestion, { SurveyQuestionDetailFormTypes } from './ComponentQuestion';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import ManagementInfo from 'common/components/ManagementInfo';
import ModalConfirm from 'common/components/ModalConfirm';
import { getQuestionByIdService, updateQuestionService } from 'common/services/survey';
import { UpdateQuestionParamsTypes } from 'common/services/survey/types';

const defaultValues: SurveyQuestionDetailFormTypes = {
  title: '',
  answers: []
};

const SurveyQuestionDetail: React.FC<ActiveRoles> = ({ roleUpdate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();

  /* Selectors */
  const rolesUser = useAppSelector((state) => state.auth.roles);
  const { languageOptions, defaultWebsiteLanguage } = useAppSelector((state) => state.system);
  const localeParam = searchParams.get('locale') || defaultWebsiteLanguage || '';

  /* States */
  const idParams = Number(searchParams.get('id'));
  const [currentLang, setCurrentLang] = useState<string>(
    localeParam
  );
  const [confirm, setConfirm] = useState<string | undefined>(undefined);

  const updateSurveyQuestionSchema = yup.object().shape({
    title: yup.string().required(t('surveyQuestionManagement.requireTitleQuestion')),
  });

  /* React-hook-form */
  const method = useForm<SurveyQuestionDetailFormTypes>({
    resolver: yupResolver(updateSurveyQuestionSchema),
    defaultValues,
    reValidateMode: 'onChange',
  });

  const { isDirty } = method.formState;

  /* Queries */
  const { data: questionByIdData, isLoading } = useQuery(
    ['question-detail', idParams, currentLang],
    () => {
      if (idParams) {
        return getQuestionByIdService(idParams);
      }
      return undefined;
    }
  );

  const { mutate: updateQuestionByIdMutate, isLoading: updateLoading } = useMutation(
    ['question-update', currentLang],
    async (params: UpdateQuestionParamsTypes) => updateQuestionService(params.id, params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(['question-detail', idParams]);
      },
      onError: () => {
        message.error(t('message.updateError'));
      },
      onSettled: () => {
        method.reset();
      }
    }
  );

  const onSubmit = async () => {
    const isValid = await method.trigger();
    if (!isValid) {
      return;
    }
    const formData = method.getValues();

    if (idParams) {
      updateQuestionByIdMutate({
        id: idParams,
        answers: formData.answers.map((item) => ({
          answerData: {
            id: item.id,
            displayOrder: Number(item.displayOrder) || 0
          },
          translations: {
            [currentLang]: {
              title: item.title,
              resultText: item.resultText,
            }
          }
        })),
        translations: {
          [currentLang]: {
            title: formData.title,
          }
        }
      });
    }
  };

  const submitForm = async () => {
    await onSubmit();
  };

  const changeLanguageAction = async (lang?: string) => {
    setConfirm(undefined);
    if (lang) {
      setCurrentLang(lang as LanguageCodeTypes);
      setSearchParams({
        id: String(idParams),
        locale: lang,
      }, { replace: true });
    }
  };

  const handleChangeLang = async (lang: LanguageCodeTypes) => {
    if (isDirty || Object.keys(method.formState.dirtyFields).length > 0) {
      setConfirm(lang);
    } else changeLanguageAction(lang);
  };

  /* Effects */
  useEffect(() => {
    if (questionByIdData) {
      const objDefault: SurveyQuestionDetailFormTypes = {
        title: questionByIdData.translations[currentLang].title,
        answers: questionByIdData.answers.map((item) => ({
          id: item.answerData.id,
          displayOrder: item.answerData.displayOrder,
          title: item.translations[currentLang].title,
          resultText: item.translations[currentLang].resultText,
        }))
      };
      method.reset(objDefault);
    } else {
      method.reset(defaultValues);
    }
  }, [questionByIdData, method, currentLang]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('surveyQuestionManagement.titleEdit')}
        rightHeader={(
          <Space size={16}>
            {
              rolesUser.includes('*') && (
                <Button
                  type="primary"
                  disabled={!!(idParams && !roleUpdate)}
                  loading={updateLoading}
                  onClick={method.handleSubmit(submitForm)}
                >
                  <SaveOutlined />
                  {t('system.save')}
                </Button>
              )
            }
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Spin size="large" spinning={updateLoading || isLoading}>
          <FormProvider<SurveyQuestionDetailFormTypes> {...method}>
            <form noValidate>
              {
                questionByIdData && (
                  <Card type="inner">
                    <Row gutter={[16, 16]}>
                      <Col xxl={18} xl={16} lg={16}>
                        <ComponentQuestion {...questionByIdData} method={method} />
                      </Col>
                      <Col xxl={6} xl={8} lg={8}>
                        <ManagementInfo
                          classNameCustom="u-mt-16"
                          createdDate={questionByIdData.questionData.createdAt ? moment(questionByIdData.questionData.createdAt).fromNow() : ''}
                          createdBy={questionByIdData.creator?.name || 'Admin'}
                          lastUpdated={questionByIdData.questionData.updatedAt ? moment(questionByIdData.questionData.updatedAt).fromNow() : ''}
                          lastUpdatedBy={questionByIdData.updater?.name || ''}
                          languageList={languageOptions}
                          currentLang={currentLang}
                          handleChangeLang={
                            (value) => value && handleChangeLang(value as LanguageCodeTypes)
                          }
                        />
                      </Col>
                    </Row>
                  </Card>
                )
              }
            </form>
          </FormProvider>
        </Spin>
        <ModalConfirm
          isShow={!!confirm}
          handleCancel={() => setConfirm(undefined)}
          handleConfirm={() => changeLanguageAction(confirm)}
          handleClose={() => setConfirm(undefined)}
        >
          {t('message.confirmSkipInfo')}
        </ModalConfirm>
      </div>
    </>
  );
};

export default SurveyQuestionDetail;
