import {
  BgColorsOutlined, DashboardOutlined, FileExcelOutlined,
  CodeSandboxOutlined, FileOutlined, IdcardOutlined, LayoutOutlined, MenuOutlined,
  ProjectOutlined, QuestionCircleOutlined, SettingOutlined,
  ToolOutlined, ContainerOutlined
} from '@ant-design/icons';

import roles from './roles';

import { MenuItem } from 'common/components/Sidebar';
import { ROUTE_PATHS } from 'common/utils/constant';

const menuSidebar: MenuItem[] = [
  {
    key: 'dashboard',
    title: 'sidebar.dashboard',
    icon: <DashboardOutlined />,
    items: [
      {
        key: 'news_dashboard',
        title: 'sidebar.newDashboard',
        path: '/',
      },
      {
        key: 'old_dashboard',
        title: 'sidebar.oldDashboard',
        path: ROUTE_PATHS.OLD_DASHBOARD,
      },
    ],
  },
  {
    key: 'page_management',
    title: 'sidebar.page',
    icon: <CodeSandboxOutlined />,
    items: [
      {
        key: 'page_list',
        title: 'sidebar.pageList',
        path: ROUTE_PATHS.PAGE_MANAGEMENT,
        role: roles.PAGE_INDEX,
        childrens: [ROUTE_PATHS.PAGE_DETAIL]
      },
      {
        key: 'page_template',
        title: 'sidebar.pageTemplate',
        path: ROUTE_PATHS.PAGE_TEMPLATE_MANAGEMENT,
        role: roles.TEMPLATE_INDEX
      },
    ],
  },
  {
    key: 'file',
    title: 'sidebar.file',
    icon: <FileOutlined />,
    path: ROUTE_PATHS.FILE_MANAGEMENT,
    role: roles.FOLDER_VIEWALL
  },
  {
    key: 'menu',
    title: 'sidebar.menu',
    icon: <MenuOutlined />,
    path: ROUTE_PATHS.MENU_MANAGEMENT,
    role: roles.MENU_INDEX,
    childrens: [ROUTE_PATHS.MENU_DETAIL]
  },
  {
    key: 'banner',
    title: 'sidebar.banner',
    icon: <LayoutOutlined />,
    path: ROUTE_PATHS.BANNER_MANAGEMENT,
    role: roles.BANNER_INDEX,
    childrens: [ROUTE_PATHS.BANNER_DETAIL]
  },
  {
    key: 'static_block',
    title: 'sidebar.staticBlocks',
    icon: <ProjectOutlined />,
    path: ROUTE_PATHS.STATIC_BLOCK_MANAGEMENT,
    role: roles.STATIC_BLOCK_INDEX,
    childrens: [ROUTE_PATHS.STATIC_BLOCK_DETAIL]
  },
  {
    key: 'kiosk',
    title: 'sidebar.sidebarKiosk',
    icon: <IdcardOutlined />,
    path: ROUTE_PATHS.KIOSK_MANAGEMENT,
    items: [
      {
        key: 'kiosk',
        title: 'sidebar.kiosk',
        path: ROUTE_PATHS.KIOSK_MANAGEMENT,
        role: roles.KIOSK_INDEX,
        childrens: [ROUTE_PATHS.KIOSK_DETAIL],
      },
      {
        key: 'kiosk_group',
        title: 'sidebar.kioskGroup',
        path: ROUTE_PATHS.KIOSK_GROUP_MANAGEMENT,
        role: roles.KIOSK_GROUP_INDEX,
        childrens: [ROUTE_PATHS.KIOSK_GROUP_DETAIL],
      },
    ]
  },
  {
    key: 'survey-question',
    title: 'sidebar.surveyQuestion',
    icon: <QuestionCircleOutlined />,
    path: ROUTE_PATHS.SURVEY_QUESTION_MANAGEMENT,
    role: roles.QUESTION_INDEX,
    childrens: [ROUTE_PATHS.SURVEY_QUESTION_DETAIL]
  },
  {
    key: 'survey-result',
    title: 'sidebar.surveyResult',
    icon: <ContainerOutlined />,
    path: ROUTE_PATHS.SURVEY_RESULT_MANAGEMENT,
    role: roles.SURVEY_RESULT,
    childrens: [ROUTE_PATHS.SURVEY_RESULT_DETAIL]
  },
  {
    key: 'reports',
    title: 'sidebar.reports',
    icon: <FileExcelOutlined />,
    path: ROUTE_PATHS.REPORTS_MANAGEMENT,
  },
  {
    key: 'tools',
    title: 'sidebar.tools',
    icon: <ToolOutlined />,
    items: [
      {
        key: 'fe_translation',
        title: 'sidebar.feTranslations',
        path: ROUTE_PATHS.FE_TRANSLATION_MANAGEMENT,
        role: roles.FE_TRANSLATION_INDEX
      },
      {
        key: 'cms_translation',
        title: 'sidebar.cmsTranslations',
        path: ROUTE_PATHS.CMS_TRANSLATION_MANAGEMENT,
        role: roles.CMS_TRANSLATION_INDEX
      },
    ]
  },
  {
    key: 'appearance',
    title: 'sidebar.appearance',
    icon: <BgColorsOutlined />,
    items: [
      {
        key: 'config-management',
        title: 'sidebar.headerFooter',
        path: ROUTE_PATHS.SYSTEM_CONFIG_MANAGEMENT,
        role: roles.CONFIG_HEADER_INDEX || roles.CONFIG_FOOTER_INDEX,
      },
    ]
  },
  {
    key: 'system',
    title: 'sidebar.system',
    icon: <SettingOutlined />,
    items: [
      {
        key: 'system_management',
        title: 'sidebar.general',
        path: ROUTE_PATHS.SYSTEM_MANAGEMENT,
        role: roles.SYSTEM_INDEX
      },
      {
        key: 'user_management',
        title: 'sidebar.users',
        path: ROUTE_PATHS.USERS_MANAGEMENT,
        role: '*',
      },
      {
        key: 'roles',
        title: 'sidebar.roles',
        path: ROUTE_PATHS.ROLES_MANAGEMENT,
        role: '*',
      },
      {
        key: 'activity_logs',
        title: 'sidebar.activityLogs',
        path: ROUTE_PATHS.ACTIVITY_MANAGEMENT,
        role: roles.ACTIVITYLOG_INDEX,
      },
    ],
  },
];

export default menuSidebar;
