import { Col, Row, Typography } from 'antd';
import React from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Input from 'common/components/Input';
import { QuestionDetailParamsTypes } from 'common/services/survey/types';

export type SurveyQuestionDetailFormTypes = {
  title: string;
  answers: Array<{
    id: number;
    title: string;
    resultText: string;
    displayOrder: number;
  }>
};

interface ComponentQuestionProps extends QuestionDetailParamsTypes {
  method: UseFormReturn<SurveyQuestionDetailFormTypes>;
}

const ComponentQuestion: React.FC<ComponentQuestionProps> = ({
  method,
}) => {
  const { t } = useTranslation();

  const {
    fields,
  } = useFieldArray({
    control: method.control,
    name: 'answers', // unique name for your Field Array
  });

  /* Render */
  return (
    <Col>
      <Row>
        <Typography.Text strong>
          {t('surveyQuestionManagement.titleQuestion')}
        </Typography.Text>
        <Typography.Text strong type="danger">
          *
        </Typography.Text>
        <Controller
          name="title"
          render={({ field, fieldState }) => (
            <Input
              type="text"
              size="large"
              required
              value={field.value}
              onChange={field.onChange}
              error={fieldState.error?.message}
              placeholder={t('surveyQuestionManagement.placeholderTitleQuestion')}
            />
          )}
        />
      </Row>
      <div className="u-mt-16">
        {fields.map((item, index) => {
          const fieldIndex = `answers.${index}`;
          return (
            <Row key={item.id} className="u-mt-24">
              <Typography.Text>
                {t('surveyQuestionManagement.answer')}
                {' '}
                {index + 1}
              </Typography.Text>
              <Controller
                name={`${fieldIndex}.title`}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    value={field.value}
                    placeholder={t('surveyQuestionManagement.placeholderTitleAnswer')}
                    error={error?.message}
                    autoComplete="off"
                    defaultValue={item.title}
                  />
                )}
              />
              {/* Text result */}
              <Typography.Text className="u-mt-10">
                {t('surveyQuestionManagement.textResult')}
              </Typography.Text>
              <Controller
                name={`${fieldIndex}.resultText`}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    value={field.value}
                    placeholder={t('surveyQuestionManagement.placeholderTextResult')}
                    error={error?.message}
                    autoComplete="off"
                    defaultValue={item.title}
                  />
                )}
              />
              {/* Display Order */}
              <Typography.Text className="u-mt-10">
                {t('newsDetail.displayOrder')}
              </Typography.Text>
              <Controller
                name={`${fieldIndex}.displayOrder`}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Input
                    className="u-mt-8"
                    type="number"
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                    size="large"
                  />
                )}
              />
            </Row>
          );
        })}
      </div>
    </Col>
  );
};

export default ComponentQuestion;
