import {
  ArcElement,
  Chart,
  registerables,
} from 'chart.js';
import React, { useCallback, useMemo } from 'react';
import {
  Bar,
  Doughnut,
  Line,
  Pie,
} from 'react-chartjs-2';

import htmlLegendPiePlugin from 'common/utils/chart';

type ShapeType = 'bar' | 'line' | 'pie' | 'doughnut';
export interface ChartCustomProps {
  data: any;
  options?: any;
  height?: number;
  width?: number;
  legendCustomId?: string;
  type?: ShapeType;
  isMultipleType?: boolean;
  typeSchema?: 'YNTCThan' | 'SLBN' | 'YNTCTimMach';
  onlyTotal?: boolean;
}
// register Plugins
Chart.register(ArcElement);
Chart.register(...registerables);
// config datalabels: https://chartjs-plugin-datalabels.netlify.app/guide/options.html#scriptable-options

const ChartCustom: React.FC<ChartCustomProps> = ({
  data, options, height, width, type, legendCustomId, typeSchema, isMultipleType,
  onlyTotal,
}) => {
  const defaultOptions = useMemo(() => ({
    ...options,
    plugins: {
      ...options?.plugins,
      legend: {
        ...options?.plugins?.legend,
        display: legendCustomId ? false : options?.plugins?.legend?.display,
      }
    }
  }), [options, legendCustomId]);

  const renderChartType = useCallback(() => {
    switch (type) {
      case 'line':
        return (
          <Line
            data={data}
            options={defaultOptions}
            height={height}
            width={width}
            plugins={
              legendCustomId ? [htmlLegendPiePlugin(legendCustomId, isMultipleType, onlyTotal)]
                : undefined
            }
          />
        );
      case 'bar':
        return (
          <Bar
            data={data}
            options={defaultOptions}
            height={height}
            width={width}
            plugins={
              legendCustomId ? [htmlLegendPiePlugin(legendCustomId, isMultipleType, onlyTotal)]
                : undefined
            }
          />
        );
      case 'pie':
        return (
          <Pie
            plugins={
              legendCustomId ? [htmlLegendPiePlugin(legendCustomId, isMultipleType, onlyTotal)]
                : undefined
            }
            data={data}
            options={defaultOptions}
            height={height}
            width={width}
          />
        );
      case 'doughnut':
        return (
          <Doughnut
            data={data}
            options={defaultOptions}
            height={height}
            width={width}
            plugins={
              legendCustomId ? [htmlLegendPiePlugin(legendCustomId, isMultipleType, onlyTotal)]
                : undefined
            }
          />
        );
      default:
        return null;
    }
  }, [type, data, height, width,
    legendCustomId, isMultipleType, onlyTotal, defaultOptions]);

  const maxWidthChart = useMemo(() => {
    if ((data?.labels.length || 1) * ((data?.datasets.length || 1) * 30) > 2000) {
      return 2000;
    }
    return (data?.labels.length || 1) * ((data?.datasets.length || 1) * 30);
  }, [data]);

  return (
    <div className={`o-chart o-chart-${type}`}>
      <div
        className={`o-chart_panel ${!(data && data?.datasets?.length && data?.labels?.length) && 'empty'}`}
        style={{ minWidth: typeSchema === 'YNTCThan' ? `${maxWidthChart}px` : '' }}
      >
        {data
          && data?.datasets?.length
          && data?.labels?.length > 0 ? renderChartType()
          : null}
      </div>
      {
        legendCustomId && <div id={legendCustomId} className="o-chart_legendCustom" />
      }
    </div>
  );
};

ChartCustom.defaultProps = {
  options: {},
  height: undefined,
  width: undefined,
  legendCustomId: '',
};

export default ChartCustom;
