import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col,
  Row,
  Space, Spin,
  Switch,
  Typography,
  message
} from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import { DropdownElement } from 'common/components/DropdownType';
import ErrorText from 'common/components/ErrorText';
import HeaderPage from 'common/components/HeaderPage';
import ManagementInfo from 'common/components/ManagementInfo';
import {
  createKioskGroupService,
  getKioskGroupByIdService, updateKioskGroupService
} from 'common/services/kiosk';
import { CreateKioskGroupParamsTypes, UpdateKioskGroupParamsTypes } from 'common/services/kiosk/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { updateKioskGroupSchema } from 'common/utils/schemas';
import { RenderInputFormField } from 'features/FormDetail/RenderUI/RenderFormElement';

export type KioskGroupFormTypes = {
  name: string;
  includeWebsite?: boolean;
  kioskIds: Array<any>;
};

const defaultValues: KioskGroupFormTypes = {
  name: '',
  includeWebsite: false,
  kioskIds: []
};

const KioskGroupDetail: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  /* Selectors */
  const { defaultWebsiteLanguage } = useAppSelector((state) => state.system);

  /* States */
  const idParams = Number(searchParams.get('id'));

  /* React-hook-form */
  const method = useForm<KioskGroupFormTypes>({
    resolver: yupResolver(updateKioskGroupSchema),
    defaultValues,
    reValidateMode: 'onChange',
  });

  /* Queries */
  const { data: kioskGroupData, isLoading } = useQuery(
    ['kioskGroup-detail', idParams],
    () => {
      if (idParams) {
        return getKioskGroupByIdService(idParams);
      }
      return undefined;
    }
  );

  const { mutate: updateKioskGroupByIdMutate, isLoading: updateLoading } = useMutation(
    ['kioskGroup-update'],
    async (params: UpdateKioskGroupParamsTypes) => updateKioskGroupService(
      params.id,
      params.kioskGroup
    ),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(['kiosk-detail', idParams]);
      },
      onError: (error) => {
        if (Array.isArray(error) && error[0].code === 'dimensionInvalid') {
          message.error(error[0]?.message);
        } else {
          message.error(t('message.createError'));
        }
      }
    }
  );

  const { mutate: createKioskGroupByIdMutate, isLoading: createLoading } = useMutation(
    ['kioskGroup-create'],
    async (params: CreateKioskGroupParamsTypes) => createKioskGroupService(params),
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigate(`${ROUTE_PATHS.KIOSK_GROUP_MANAGEMENT}`);
      },
      onError: (error) => {
        if (Array.isArray(error) && error[0].code === 'dimensionInvalid') {
          message.error(error[0]?.message);
        } else {
          message.error(t('message.createError'));
        }
      }
    }
  );

  const onSubmit = async (addingFn?: (id: number) => void) => {
    const isValid = await method.trigger();

    const formData = method.getValues();

    if (!isValid) {
      return;
    }

    if (idParams) {
      updateKioskGroupByIdMutate({
        id: idParams,
        kioskGroup: {
          includeWebsite: formData.includeWebsite,
          name: formData.name,
          kioskIds: formData.kioskIds
        }
      });
    } else {
      createKioskGroupByIdMutate(formData, {
        onSuccess: (data) => addingFn && addingFn(data.id),
      });
    }
  };

  const submitForm = async () => {
    await onSubmit();
  };

  /* Effects */
  useEffect(() => {
    if (kioskGroupData) {
      const objDefault: KioskGroupFormTypes = {
        ...kioskGroupData.kioskGroupData,
        kioskIds: kioskGroupData?.kiosks.map((ele) => ele.id)
      };
      method.reset(objDefault);
    } else {
      method.reset(defaultValues);
    }
  }, [kioskGroupData, method]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={idParams ? t('kioskManagement.titleEditKioskGroup') : t('kioskManagement.titleCreateKioskGroup')}
        rightHeader={(
          <Space size={16}>
            <Button
              type="primary"
              disabled={(idParams && !roleUpdate) || (!idParams && !roleCreate)}
              loading={updateLoading || createLoading}
              onClick={method.handleSubmit(submitForm)}
            >
              <SaveOutlined />
              {t('system.save')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Spin size="large" spinning={updateLoading || isLoading || createLoading}>
          <FormProvider {...method}>
            <Row gutter={16}>
              <Col xxl={18} xl={16} lg={16}>
                <Card type="inner">
                  <Row gutter={[16, 16]}>
                    {/* Tên */}
                    <Col span={24}>
                      {/* name */}
                      <RenderInputFormField
                        required
                        propertyKey="name"
                      />
                    </Col>
                    <Col span={24}>
                      <div>
                        <Typography.Text strong>
                          {t('kioskManagement.includeWebsite')}
                        </Typography.Text>
                        <Controller
                          name="includeWebsite"
                          render={({ field }) => (
                            <div>
                              <Switch
                                checked={field.value}
                                onChange={field.onChange}
                              />
                            </div>
                          )}
                        />
                      </div>
                    </Col>
                    <Col>
                      <Typography.Text strong>
                        {t('kioskManagement.kioskIds')}
                      </Typography.Text>
                      <Controller
                        name="kioskIds"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <>
                            <DropdownElement
                              type="kiosk"
                              placeholder={`${t('system.select')} kiosk`}
                              locale={defaultWebsiteLanguage || 'vi'}
                              value={value}
                              onChange={onChange}
                              isShowSearch
                              multiple={{}}
                            />
                            {
                              error && (
                                <ErrorText>
                                  {error.message}
                                </ErrorText>
                              )
                            }
                          </>
                        )}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xxl={6} xl={8} lg={8}>
                <ManagementInfo
                  createdDate={kioskGroupData ? moment(kioskGroupData.kioskGroupData?.createdAt).fromNow() : ''}
                  createdBy={kioskGroupData?.creator.name || ''}
                  lastUpdated={kioskGroupData ? moment(kioskGroupData.kioskGroupData?.updatedAt).fromNow() : ''}
                  lastUpdatedBy={kioskGroupData?.updater?.name || ''}
                />
              </Col>
            </Row>
          </FormProvider>
        </Spin>
      </div>
    </>
  );
};

export default KioskGroupDetail;
