import {
  Col,
  Row,
  Space
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'app/store';
import roles, { getPermission } from 'configs/roles';
import VerticalBarChartV2 from 'extends/Charts/VerticalBarChartV2';

const gutterValue = 16;

const OldDashboard: React.FC = () => {
  const { t } = useTranslation();
  const rolesUser = useAppSelector((state) => state.auth.roles);

  return (
    <div className="p-home">
      <Space direction="vertical" size={gutterValue} style={{ width: '100%' }}>
        {
          getPermission(rolesUser, roles.OLD_DATA_REPORT_SURVEY_KIDNEYRISK) && (
            <Row gutter={[gutterValue, gutterValue]}>
              <Col xxl={24}>
                <VerticalBarChartV2
                  title={t('dashboard.titleSurveyKidneyRisk')}
                  typeSchema="YNTCThan"
                  height={110}
                  isOlData
                  legendCustomId="legendCustomKidney"
                />
              </Col>
            </Row>
          )
        }
        <Row gutter={[gutterValue, gutterValue]}>
          <Col xxl={12} lg={12} sm={24}>
            {
              getPermission(rolesUser, roles.OLD_DATA_REPORT_SURVEY_COUNTER) && (
                <VerticalBarChartV2
                  title={t('dashboard.titleCounter')}
                  typeSchema="SLBN"
                  height={240}
                  isOlData
                  legendCustomId="legendCustomCounter"
                />
              )
            }
          </Col>
          <Col xxl={12} lg={12} sm={24}>
            {
              getPermission(rolesUser, roles.OLD_DATA_REPORT_SURVEY_HEARTRISK) && (
                <VerticalBarChartV2
                  title={t('dashboard.titleSurveyHeart')}
                  typeSchema="YNTCTimMach"
                  height={240}
                  isOlData
                  legendCustomId="legendCustomHeart"
                />
              )
            }
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default OldDashboard;
